import React from 'react';
import { Text, Card } from 'rebass';

export const ReadOnlyDisclaimer = () => (
  <Card p={4} mb={4} bg="intent.warning" sx={{ boxShadow: 0 }}>
    <Text color="black">
      <b>
        This site is deprecated in favor of LFX Project Control Center:{' '}
        <a href="https://pcc.lfx.dev">https://pcc.lfx.dev</a>. It may be taken
        down in the future with little to no notice.
      </b>
    </Text>
  </Card>
);

export const AdminDisclaimer = () => (
  <>
    <Card p={4} mb={4} bg="intent.warning" sx={{ boxShadow: 0 }}>
      <Text color="black">
        <b>
          This site is deprecated in favor of LFX Project Control Center:{' '}
          <a href="https://pcc.lfx.dev">https://pcc.lfx.dev</a>. It may be taken
          down in the future with little to no notice.
        </b>
      </Text>
    </Card>
    <Card p={4} mb={4} bg="intent.danger" sx={{ boxShadow: 0 }}>
      <Text color="black">
        <b>PLEASE NOTE:</b> as of April 19th, 2021, projects must be created in
        Salesforce; however, any project created in Salesforce will show up here
        automatically.
      </Text>
    </Card>
    <Card p={4} mb={4} bg="intent.warning" sx={{ boxShadow: 0 }}>
      <Text color="black">
        This application provides direct access to IT Service Automation.{' '}
        <b>
          Actions taken here cannot be undone and have immediate financial and
          operational impact.
        </b>{' '}
        By using this tool, you agree that you are responsible for ensuring that
        provisioned services have funding in place. Prices shown on this site
        represent internal LF costs; please consult{' '}
        <a
          href="https://confluence.linuxfoundation.org/x/dpY_Aw"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://confluence.linuxfoundation.org/x/dpY_Aw
        </a>{' '}
        for current IT Service fees.
      </Text>
    </Card>
  </>
);
